import { useUser } from 'components/use-user/auth-context'
import { routes } from 'const/routes'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'ui'
import {
  getCurrentIndex,
  getUserInitialRoute,
  ProgressData,
  steps,
  UserStep,
  getRenterProfileSteps,
} from './steps'

interface UserProgressSteps {
  restore: (data: ProgressData, profileOnly?: boolean) => void
  next: (query?: Record<string, string>) => void
  nextRoute(index?: number): string | null
  previous: () => void
  previousRoute: () => string | null
  getRenterProfileSteps: (data: ProgressData) => UserStep[]
}

const EXCLUDE_FROM_RESTORE: string[] = [routes.passwordRecovery, routes.passwordReset]

export function useUserProgressSteps(): UserProgressSteps {
  const navigate = useNavigate()
  const location = useLocation()
  const [user] = useUser()

  const api = useMemo<UserProgressSteps>(() => {
    const api: UserProgressSteps = {
      restore(data) {
        if (!EXCLUDE_FROM_RESTORE.includes(location.pathname)) {
          navigate(getUserInitialRoute(data))
        }
      },
      nextRoute(currentStepIndex = getCurrentIndex(location.pathname)): string | null {
        if (!user) return null
        if (currentStepIndex >= steps.length - 1) return null
        const { isVisible, route } = steps[currentStepIndex + 1]
        if (isVisible && !isVisible({ user })) return api.nextRoute(currentStepIndex + 1)
        return route ?? null
      },
      next(query?: Record<string, string>) {
        const route = api.nextRoute()
        if (route) {
          navigate({
            pathname: route,
            search: query ? `?${new URLSearchParams(query).toString()}` : undefined,
          })
        }
      },
      previous() {
        const currentStepIndex = getCurrentIndex(location.pathname)
        if (currentStepIndex > 0) {
          const route = steps[currentStepIndex - 1].route
          navigate(route)
        }
      },
      previousRoute() {
        const currentStepIndex = getCurrentIndex(location.pathname)
        return currentStepIndex > 0 ? steps[currentStepIndex - 1].route : null
      },
      getRenterProfileSteps,
    }
    return api
  }, [location.pathname, navigate, user])

  return api
}
