import { UserProvider } from 'components/use-user'
import { UserLeasesProvider } from 'components/use-user-menu'
import { auth } from 'pages/auth'
import { FC, useEffect } from 'react'
import { ScrollRestoration } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { Outlet } from 'ui'
import { AuthProvider } from 'web-auth'

export const Root: FC = () => {
  useEffect(injectStyle, [])
  return (
    <UserProvider>
      <UserLeasesProvider>
        <AuthProvider auth={auth}>
          <Outlet />
        </AuthProvider>
        <ToastContainer theme="colored" />
        <ScrollRestoration />
      </UserLeasesProvider>
    </UserProvider>
  )
}
