import { Page } from 'components/page'
import { routes } from 'const/routes'
import { useEffect } from 'react'
import { To, useRouteError } from 'react-router-dom'
import { LinkButton } from 'ui'
import { logError } from 'utils/remote-logger'
import styles from './404.module.scss'

export default function NotFoundPage({
  inner,
  path = routes.welcome,
}: {
  inner?: boolean
  path?: To
}) {
  const error = useRouteError() as Error
  useEffect(() => {
    logError(error?.message ?? '404', { context: 'Page Not Found' })
  }, [error?.message])

  return inner ? (
    <section className={styles.page}>
      <h1>Page not Found</h1>
      <LinkButton theme="primary" to={path}>
        Go Back
      </LinkButton>
    </section>
  ) : (
    <Page className={styles.page}>
      <h1>Page not Found</h1>
      <LinkButton theme="primary" to={path}>
        Back to Home Screen
      </LinkButton>
    </Page>
  )
}
