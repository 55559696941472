import { FC, HTMLProps } from 'react'
import { To } from 'react-router-dom'
import { Logo } from 'ui'
import { cn } from 'utils'
import styles from './header.module.scss'

interface HeaderProps extends HTMLProps<HTMLDivElement> {
  logoLinkTo?: To
  adminLayout?: boolean
}

export const Header: FC<HeaderProps> = ({ className, children, logoLinkTo, ...props }) => {
  return (
    <header className={cn(styles.header, className)} {...props}>
      <Logo linkTo={logoLinkTo} />
      {children}
    </header>
  )
}
