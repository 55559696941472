import { Cosigner, CurrentUser, Lease, User } from 'api/hello'
import { routes } from 'const/routes'
import { matchPath } from 'react-router-dom'

export interface ProgressData {
  user?: CurrentUser | null
}

export interface Step {
  route: string
  routePattern?: string
  isCompleted: (progress: ProgressData) => boolean
  isVisible?: (progress: ProgressData) => boolean
  renterProfile?: boolean
  label?: string
}

export const isPhoneVerified = (user?: User | null) =>
  !!(user?.phone_number && user?.phone_verified_at)

export const isProfileContactsComplete = (user?: User | null) =>
  !!(user?.dob && user?.first_name && user?.last_name)

export const isProfileFinanceComplete = (user?: User | null) =>
  User.hasScore(user) || !!user?.voucher

export const isProfileAdditionalComplete = (user?: User | null) => true

export const steps: Step[] = [
  {
    route: routes.welcome,
    isCompleted: ({ user }) => !!user,
  },
  {
    route: routes.phoneVerifyDialog,
    isCompleted: ({ user }) => isPhoneVerified(user),
  },
  {
    route: routes.terms,
    isCompleted: ({ user }) => !!user?.terms_accepted_at,
  },
  {
    route: routes.infoDialog,
    isCompleted: () => true,
  },
  {
    route: routes.profileContacts,
    routePattern: `${routes.profileContacts}/*`,
    isCompleted: ({ user }) => isProfileContactsComplete(user),
    renterProfile: true,
    label: 'Contact',
  },
  {
    route: routes.linkedAccounts,
    isCompleted: ({ user }) => {
      if (!isProfileContactsComplete(user)) return false
      if (user?.cosigner && Cosigner.isPending(user.cosigner)) return false
      return true
    },
    renterProfile: true,
    label: 'Linked Accounts',
  },
  {
    route: routes.finance,
    isCompleted: ({ user }) => isProfileContactsComplete(user) && isProfileFinanceComplete(user),
    renterProfile: true,
    label: 'Financial',
  },
  {
    route: routes.profileAdditionalData,
    isCompleted: ({ user }) =>
      isProfileContactsComplete(user) &&
      isProfileFinanceComplete(user) &&
      isProfileAdditionalComplete(user),
    renterProfile: true,
    label: 'Additional Info',
  },
  {
    route: routes.profileSubmit,
    isCompleted: ({ user }) => {
      return (
        // prev steps are completed
        isProfileContactsComplete(user) &&
        isProfileFinanceComplete(user) &&
        isProfileAdditionalComplete(user) &&
        // user has active lease
        !!user?.leases?.some(Lease.isOK)
      )
    },
    isVisible: ({ user }) => User.hasInvitedUnitId(user),
    renterProfile: true,
    label: 'Submit',
  },
]

export function getUserInitialRoute(data: ProgressData) {
  const incompleteStep = getFirstIncompleteRoute(data)
  if (incompleteStep) return incompleteStep

  // https://app.shortcut.com/rello/story/4481/after-owner-selects-application-when-user-logs-in-it-should-take-them-to-checklist-page-rather-than-edit-application-page
  if (data.user?.leases?.some(Lease.isOK)) return routes.myHome

  return routes.search
}

export function getFirstIncompleteRoute(data: ProgressData) {
  const index = steps.findIndex(
    (step) => (step.isVisible ? step.isVisible(data) : true) && !step.isCompleted(data),
  )
  return index === -1 ? null : steps[index].route
}

export function getCurrentIndex(pathname: string): number {
  return steps.findIndex(({ route, routePattern }) => matchPath(routePattern ?? route, pathname))
}

export type UserStep = {
  label: string
  route: string
  completed: boolean
  disabled: boolean
}

export const getRenterProfileSteps = ({ user }: ProgressData) => {
  const result: UserStep[] = []
  for (let step of steps) {
    if (!step.renterProfile) continue
    if (step.isVisible && !step.isVisible({ user })) continue
    const prevCompleted = result.length ? !!result.at(-1)?.completed : true
    result.push({
      label: step.label!,
      route: step.route,
      completed: prevCompleted && !!step.isCompleted({ user }),
      disabled: !prevCompleted,
    })
  }
  return result
}

export const getRenterProfileIncompletedStep = (data: ProgressData) => {
  const steps = getRenterProfileSteps(data)
  return steps.find((step) => !step.completed) ?? undefined
}
