import { FC, HTMLProps } from 'react'
import { To } from 'react-router-dom'
import { Logo } from 'ui'
import { cn } from 'utils'
import { FooterNav } from './footer-nav'
import styles from './footer.module.scss'

interface FooterProps extends HTMLProps<HTMLDivElement> {
  logoLinkTo?: To
}

export const Footer: FC<FooterProps> = ({ className, children, logoLinkTo, ...props }) => {
  return (
    <footer className={cn(styles.footer, className)} {...props}>
      <Logo variation="faded" className={styles.logo} linkTo={logoLinkTo} />
      <FooterNav className={styles.nav} />
      {children}
      <p className={styles.footnotes}>
        <span>© {new Date().getFullYear()} Rello Group Inc. All rights reserved.</span>
        {import.meta.env.VITE_BUILD_INFO && (
          <span className={styles.build}>{import.meta.env.VITE_BUILD_INFO}</span>
        )}
      </p>
    </footer>
  )
}
