import { MimeType, UserFile } from 'api/user-file'
import { IconOpenInNew } from 'icons'
import { FC } from 'react'
import { Description, IconButton, Image, PureDialog } from 'ui'
import { cn } from 'utils'
import styles from './file-preview.module.scss'

interface Props {
  url?: string
  type: MimeType
  title: string
  className?: string
  onClose?: () => void
}

export const ChatFilePreview: FC<Props> = ({ url, type, title, className, onClose }) => {
  const isPDF = type ? !!type.match(/pdf$/) : false
  const isImage = type ? !!type.match(/^image\/.*/) : false
  const error = !url
    ? UserFile.MSG.ERR.NOT_FOUND
    : !isPDF && !isImage
    ? UserFile.MSG.ERR.UNSUPPORTED
    : undefined

  const open = () => {
    url && window.open(url, '_blank')
  }

  return (
    <PureDialog
      className={cn(styles.dialog, isImage && styles.image, isPDF && styles.pdf, className)}
      onClose={onClose}
    >
      <IconButton className={styles.open} onClick={open}>
        <IconOpenInNew />
      </IconButton>

      {error ? (
        <Description error className={styles.error}>
          <ul>
            <li>{error}</li>
          </ul>
        </Description>
      ) : isImage ? (
        <Image src={url} className={cn(styles.img)} />
      ) : null}
    </PureDialog>
  )
}
