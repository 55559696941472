import { Client, GetConfig } from 'client'
import { ScoreWarnings, UserScoreWarning } from './score-warnings'

export class ScoreWarningsBackend extends Client {
  getScoreWarnings = async (config?: GetConfig): Promise<UserScoreWarning[]> => {
    const { warnings } = await this.get<{ warnings: ScoreWarnings; status: string }>(
      '/user/score/warnings/get',
      undefined,
      config,
    )
    return ScoreWarnings.toUserWarnings(warnings)
  }
}

export const scoreWarnings = new ScoreWarningsBackend()
