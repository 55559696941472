import { api } from 'api/hello'
import { router } from 'pages/router'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { Loader } from 'ui'
import { initLogger } from 'utils/remote-logger'
import 'ui/index.scss'

try {
  const appProtocol = import.meta.env.VITE_APP_PROTOCOL
  if (appProtocol && (navigator.platform ? /iPad|iPhone/.test(navigator.platform) : true)) {
    window.location.replace(
      `${appProtocol}://${window.location.pathname}?${window.location.search}`,
    )
  }
} catch (e) {
  //
}

initLogger(api.user.addUserListener, import.meta.env.VITE_LOGGER_KEY)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<RouterProvider router={router} fallbackElement={<Loader />} />)

const gtmId = import.meta.env.VITE_GTAG_ID
if (gtmId) {
  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`
  document.body.prepend(script)
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  window.gtag = function () {
    //@ts-ignore
    dataLayer.push(arguments)
  }
  //@ts-ignore
  gtag('js', new Date())
  //@ts-ignore
  gtag('config', gtmId)
} else {
  // eslint-disable-next-line no-console
  console.warn('No GTM ID found')
}
