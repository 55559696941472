import { SignedIn } from 'components/signed-in'
import { routes } from 'const/routes'
import { FC } from 'react'
import { Link } from 'ui'
import { cn } from 'utils'
import styles from './footer-nav.module.scss'

interface Props {
  className?: string
}

export const FooterNav: FC<Props> = ({ className }) => {
  return (
    <nav className={cn(styles.nav, className)}>
      <ul className={styles.list}>
        <li>
          <Link className={styles.link} to={routes.about}>
            About
          </Link>
        </li>
        <SignedIn out={null} hideWhileLoading>
          <li>
            <Link className={styles.link} to={routes.help}>
              Help
            </Link>
          </li>
        </SignedIn>
        <li>
          <Link className={styles.link} to={routes.contacts}>
            Contact Us
          </Link>
        </li>
        <li>
          <Link className={styles.link} to={routes.privacyPolicy}>
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link className={styles.link} to={routes.termsOfService}>
            Terms of Service
          </Link>
        </li>
      </ul>
    </nav>
  )
}
