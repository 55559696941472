import { FC } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Outlet } from 'ui'
import { LayoutConfig, useLayoutConfig } from './context'

export const LayoutUse: FC<LayoutConfig> = ({ header }) => {
  useLayoutConfig({ header })
  const context = useOutletContext()
  return <Outlet context={context} />
}
