import { CurrentUser } from 'api/hello'
import { Page } from 'components/page'
import { RelloBackground } from 'components/rello-background'
import { useUser } from 'components/use-user'
import { useUserProgressSteps } from 'components/use-user-progress-steps'
import { routes } from 'const/routes'
import { useEffect } from 'react'
import { useLocation, useMatch } from 'react-router-dom'
import { Logo, Outlet, LinkButton, Link } from 'ui'
import styles from './index.module.css'

export default function Home() {
  const [user] = useUser()
  const steps = useUserProgressSteps()
  const { pathname, state, search } = useLocation()
  const isRoot = !!useMatch({ path: routes.welcome, end: true })

  const hash = CurrentUser.parseInvite(search)
  useEffect(() => {
    CurrentUser.saveAndTrackInvite(hash)
  }, [hash])

  useEffect(() => {
    if (isRoot && (user || state?.error)) {
      steps.restore({ user })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, state?.error])

  return (
    <Page className={styles.page}>
      <RelloBackground
        className={styles.video}
        play={pathname === routes.welcome}
        contentClassName={styles.content}
        shadeClassName={styles.shade}
      >
        <h1 className={styles.welcome}>
          <span>Welcome to</span>
          <Logo variation="welcome" className={styles.logo} />
        </h1>
        <h2 className={styles.slogan}>The easier way to rent.</h2>
        <div className={styles.bottom}>
          <div className={styles.text}>
            <p>
              Rello is currently available by invite only. If you got an email from us or your
              agent, please click or copy & paste your unique invite link.
            </p>
            <p>
              If you did not get an invite mail or are just curious to chat, feel free to reach out
              via the contact us form below
            </p>
          </div>
          <LinkButton theme="primary" to={routes.signUp} className={styles.start}>
            Hi! Let&apos;s get started!
          </LinkButton>

          <div className={styles.footer}>
            Are you a Property Manager / Owner?{' '}
            <Link to={routes.forPropertyManagers}>Click Here to Learn More</Link>
          </div>
        </div>
      </RelloBackground>
      <Outlet />
    </Page>
  )
}
