import {
  OwnerPaymentAccountType,
  Unit,
  Cosigner,
  UserDocument,
  Guarantee,
  Lease,
  UnitPhoto,
  UserSignature,
} from 'api/hello'
import { createSearchParams, generatePath } from 'react-router-dom'

interface DocumentId {
  document_id: string
}
interface InstitutionId {
  institution_id: string
}

export const routes = {
  about: '/about',
  contacts: '/contact-us',

  account: '/account',
  accountInitials: 'initials-view',
  accountSignature: 'signature/:type',
  accountSignatureDraw: 'draw',
  accountSignatureType: 'type',
  accountSignatureLoad: 'load',
  accountSignatureReset: 'reset-signature',
  accountViewPhotoId: '/account/photo-id',
  accountUploadPhotoId: '/account/upload-photo-id',
  deleteAccount: '/account/delete',
  reloginAccount: '/account/relogin',
  help: '/help',

  myHome: '/my-home',

  myHomeLease: '/my-home/:lease_id',
  myHomeLeaseDetails: 'lease',
  myHomeLeaseDetailsUploadId: 'upload-id',
  myHomeLeaseDetailsViewId: 'view-id',
  myHomeLeaseDetailsBackgroundCheck: 'background-check',
  myHomeLeaseDetailsSignatureConfirm: 'signature-confirm',
  myHomeLeaseDetailsSign: 'sign',
  myHomeLeaseDetailsContract: 'contract',

  myHomeLeasePayment: 'payment/:account_type?',
  myHomeFinixPayment: '/my-home/:lease_id?/payment/finix-payment',
  myHomeFinixPaymentForm: 'payment/finix-payment',

  myHomeLeaseAutopay: 'autopay',
  myHomeLeaseAutopayConfirm: 'confirm',
  myHomeLeaseAutopayCancel: 'cancel',

  myHomeLeaseHistory: 'history',

  myHomeLeaseMaintenance: 'maintenance',
  myHomeLeaseMaintenanceSuccess: 'success',

  myHomeGuaranteeLeases: '/my-home/guarantee-leases',
  myHomeGuaranteeLease: '/my-home/:lease_id',

  myHomePaymentsAutoCallback: '/my-home/payments/subscription/callback',
  myHomePaymentsAutoCancelCallback: '/my-home/payments/subscription/cancel',

  myHomePaymentCallback: '/my-home/payments/callback',
  myHomePaymentCancel: '/my-home/payments/cancel',

  profile: '/profile',
  profileContacts: '/profile/contacts',
  finance: '/profile/finance',
  financeAddBankAccountDialog: '/profile/finance/account',
  financeAddDocumentDialog: '/profile/finance/documents',
  financeScoreWarning: '/profile/finance/warning',
  financeDeleteInstitutionDialog: '/profile/finance/institution/:id/delete',
  financeDeleteDocumentDialog: '/profile/finance/document/:id/delete',
  financeOAuthAssets: '/profile/finance/oauth/assets',
  financeUploadDocumentsDialog: '/profile/finance/upload/:type?',
  financeCheckRequiredDocumentsDialog: '/profile/finance/required-documents',
  financeExplainDisabled: '/profile/finance/explain-disabled',

  invalidEmail: '/invalid-email',

  linkedAccounts: '/profile/linked-accounts',
  linkedAccountsAcceptCosignerDialog: '/profile/linked-accounts/cosigner/:id/accept',
  linkedAccountsAcceptGuaranteeDialog: '/profile/linked-accounts/guarantee/:id/accept',
  linkedAccountsAddCosignersDialog: '/profile/linked-accounts/cosigners',
  linkedAccountsAddGuarantorDialog: '/profile/linked-accounts/guarantor',
  linkedAccountsDeclineCosignerDialog: '/profile/linked-accounts/cosigner/:id/decline',
  linkedAccountsDeclineGuaranteeDialog: '/profile/linked-accounts/guarantee/:id/decline',
  linkedAccountsDeleteCosignerDialog: '/profile/linked-accounts/cosigner/:id/delete',
  linkedAccountsDeleteGuarantorDialog: '/profile/linked-accounts/guarantor/delete/:guarantor_id',

  profileAdditionalData: '/profile/additional',
  profileAdditionalDataNoActions: 'no-actions',
  profileAdditionalDataApplicationChecklist: 'checklist',

  profileSubmit: '/profile/submit',
  profileSubmitViewPhoto: 'gallery/:photo_id',

  notFound: '/404',
  passwordLogin: '/password-login',
  passwordRecovery: '/password-recovery',
  passwordReset: '/password-reset',
  passwordSignUp: '/password-sign-up',
  privacyPolicy: '/privacy-policy',

  phoneConfirmationDialog: '/phone/confirmation',
  phoneVerifyDialog: '/phone/verify',

  unitDetails: 'unit/:id',
  unitDetailsInfo: 'info',
  unitDetailsGallery: 'gallery/:photo_id',
  unitDetailsApplication: 'apply',
  unitDetailsApplicationChecklist: 'checklist',
  unitDetailsRentNow: 'rent-now',
  unitDetailsWhyCantBid: 'why',
  unitDetailsBid: 'bid',
  unitDetailsFee: 'fee',
  unitDetailsCancelBid: 'cancel',
  unitDetailsSuccessPayment: 'success',

  search: '/search',
  searchMap: '/search/map',
  searchFilters: 'filters',
  searchNoResults: 'no-results',
  searchGrid: '/search/grid',

  signUp: '/sign-up',
  terms: '/terms',
  infoDialog: '/info',
  termsOfService: '/terms-of-service',
  welcome: '/',

  paymentCallback: '/payment-callback',
  creditScoreStart: 'credit-score-start',
  creditScoreComplete: 'credit-score-complete',
  creditScorePayment: 'credit-score-payment',

  forPropertyManagers: 'for-property-managers',
  forPropertyManagersContactUs: 'contact-us',

  streetEasy: '/se',
  streetEasyPassword: 'password',
} as const

export const getFinanceDeleteInstitutionDialog = ({ institution_id: id }: InstitutionId) =>
  generatePath(routes.financeDeleteInstitutionDialog, { id })

export const getDeclineGuaranteeDialog = (guaranteeRequest: Guarantee.Request) => {
  return generatePath(routes.linkedAccountsDeclineGuaranteeDialog, {
    id: guaranteeRequest.guarantee_id,
  })
}
export const getFinanceUploadDocumentDialog = ({ type }: { type: UserDocument.Type }) => {
  return generatePath(routes.financeUploadDocumentsDialog, { type })
}

export const getFinanceDeleteDocumentDialog = ({ document_id: id }: DocumentId) => {
  return generatePath(routes.financeDeleteDocumentDialog, { id })
}

export const getAcceptGuaranteeDialog = (guaranteeRequest: Guarantee.Request) => {
  return generatePath(routes.linkedAccountsAcceptGuaranteeDialog, {
    id: guaranteeRequest.guarantee_id,
  })
}
export const getAcceptCosignerDialog = ({ user_id: id }: Cosigner) =>
  generatePath(routes.linkedAccountsAcceptCosignerDialog, { id })

export const getDeclineCosignerDialog = ({ user_id: id }: Cosigner) =>
  generatePath(routes.linkedAccountsDeclineCosignerDialog, { id })

export const getDeleteCosignerDialog = ({ user_id: id }: Cosigner) =>
  generatePath(routes.linkedAccountsDeleteCosignerDialog, { id })

export const getUnitDetails = ({ unit_id: id }: Unit.Id) => generatePath(routes.unitDetails, { id })

export const getMapUnitDetails = (q: Unit.Id) => `${routes.searchMap}/${getUnitDetails(q)}`

export const getUnitDetailsGallery = ({ photo_id }: UnitPhoto.Id) =>
  generatePath(routes.unitDetailsGallery, { photo_id })

export const getMyHomeLease = (q: Lease.Id) => generatePath(routes.myHomeLease, q)

export const getMyHomeLeasePayment = (
  q: Lease.Id,
  { account_type }: { account_type?: OwnerPaymentAccountType } = {},
) =>
  `${getMyHomeLease(q)}/${generatePath(routes.myHomeLeasePayment, {
    account_type: account_type ?? null,
  })}`

export const getMyHomeLeaseAutopay = (q: Lease.Id) =>
  `${getMyHomeLease(q)}/${routes.myHomeLeaseAutopay}`

export const getMyHomeLeaseBackgroundCheck = (q: Lease.Id) =>
  `${getMyHomeLease(q)}/${routes.myHomeLeaseDetailsBackgroundCheck}`

export const getProfileSubmitViewPhoto = (q: UnitPhoto.Id) =>
  `${routes.profileSubmit}/${generatePath(routes.profileSubmitViewPhoto, q)}`

export const getCreditScoreStart = (parentPage: (typeof routes)[keyof typeof routes]) => {
  const searchParams = createSearchParams({
    context: JSON.stringify({
      redirectTo: `${parentPage}/${routes.creditScoreComplete}`,
      cancelRedirectTo: `${parentPage}`,
    }),
  })
  return `${parentPage}/${routes.creditScoreStart}?${searchParams.toString()}`
}

export const getSignatureByType = (q: { type: UserSignature.Type }) =>
  generatePath(routes.accountSignature, q)

export const getLeaseSign = (q: Lease.Id) =>
  `${getMyHomeLease(q)}/${routes.myHomeLeaseDetails}/${routes.myHomeLeaseDetailsSign}`

export const getLinkedAccountsDeleteGuarantorDialog = (q: { guarantor_id: string }) =>
  generatePath(routes.linkedAccountsDeleteGuarantorDialog, q)
