import { CurrentUser, Lease, LeaseChecklist, Severity, User, api } from 'api/hello'
import { GetConfig, PostConfig } from 'client'
import { useUser } from 'components/use-user'
import { ReactNode, FC, useCallback } from 'react'
import { useAsyncEffect } from 'ui'
import { every, pickAll } from 'utils/compose'
import { LeaseSummary, LeaseSummaryContext, LeaseSummaryState } from './context'

export const UserLeasesProvider: FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
  const [user] = useUser()
  const hash = user?.user_id + pickAll('lease_id', user?.leases ?? []).join('')

  const [state, , setState] = useAsyncEffect(
    async (config) => (!user?.user_id ? [] : await getSummariesForUser(user, config)),
    [hash],
  )
  const update = useCallback(async () => {
    if (!user?.user_id) return
    setState(await getSummariesForUser(user))
  }, [setState, user])

  return (
    <LeaseSummaryContext.Provider value={[state ?? EMPTY, update]}>
      {children}
    </LeaseSummaryContext.Provider>
  )
}

const EMPTY = [] as LeaseSummaryState

const getSummariesForUser = async (
  user: CurrentUser,
  config?: PostConfig,
): Promise<LeaseSummaryState> => {
  const leases = user.leases.filter(every(Lease.isNotLocked))
  const summaries = await Promise.all(
    leases.map((lease) => processLease({ lease, own: true }, config)),
  )
  const shouldCheckGuaranteeLeases = User.isGuarantor(user)
  if (!shouldCheckGuaranteeLeases) return summaries
  const guaranteeLeases = await api.lease.listActive(
    { filter: { guarantor_id: [user.user_id] } },
    config,
  )
  const guaranteeSummaries = await Promise.all(
    guaranteeLeases.map((lease) => processLease({ lease, own: false }, config)),
  )
  return [...summaries, ...guaranteeSummaries]
}

const processLease = async ({ lease, own }: { lease: Lease; own: boolean }, config?: GetConfig) => {
  const result = {
    lease_id: lease.lease_id,
    lease,
    own,
    title: own ? lease.unit?.name ?? 'My Lease' : 'Guarantee Lease',
  } as LeaseSummary
  if (Lease.isChecklistLocked(lease)) return result

  if (!Lease.isChecklistCompleted(lease)) {
    const checklist = await api.leaseChecklist.listByLeaseId(
      { lid: lease.lease_id, mine: 'true' },
      config,
    )
    result.checklistBadge = {
      amount: checklist.filter(LeaseChecklist.isActionNeeded).length,
      severnity: Severity.error,
    }
  }
  if (Lease.isChecklistCompleted(lease)) {
    const invoice = await api.lease.getInvoice({ lid: lease.lease_id }, config)
    result.paymentBadge = {
      amount: invoice.length,
      severnity: Severity.error,
    }
  }
  return result
}
