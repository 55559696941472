import { ComponentProps, FC } from 'react'
import { LoaderWrapper, usePathTransition } from 'ui'
import { cn } from 'utils'
import styles from './page.module.scss'

type Props = ComponentProps<'div'> & {
  tagName?: ComponentProps<typeof LoaderWrapper>['tagName']
}

export const Page: FC<Props> = ({ className, children, tagName = 'main', ...props }) => {
  const loading = usePathTransition({ ignoreSamePath: true })
  return (
    <LoaderWrapper
      loading={loading}
      tagName={tagName}
      className={cn(styles.page, className)}
      {...props}
    >
      {children}
    </LoaderWrapper>
  )
}
