/* eslint-disable no-console */
import { configureAbly } from '@ably-labs/react-hooks'
import { api, subscription, CurrentUser } from 'api/hello'
import { Client } from 'client'
import { Auth } from 'web-auth'

const apiKey = import.meta.env.VITE_API_KEY
const projectId = import.meta.env.VITE_PROJECT_ID
const appId = import.meta.env.VITE_FIREBASE_APP_ID
const authDomain =
  import.meta.env.NODE_ENV === 'development'
    ? `${projectId}.firebaseapp.com`
    : import.meta.env.VITE_AUTH_DOMAIN

console.assert(apiKey, 'VITE_API_KEY is not set')
console.assert(projectId, 'VITE_PROJECT_ID is not set')
console.assert(appId, 'VITE_FIREBASE_APP_ID is not set')
console.assert(authDomain, 'VITE_AUTH_DOMAIN is not set')

export const auth = new Auth(
  {
    apiKey,
    projectId,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId: '543930959980',
    appId,
    authDomain,
  },
  async function getUser({ token }) {
    if (!token) return null
    const _rlh = CurrentUser.getInvite()
    const user = await api.user.fetchUser(_rlh ? { params: { _rlh } } : undefined)
    if (!user) throw new Error('Unknown User')
    CurrentUser.clearInvite()
    return user
  },
)

Client.connectWithAuthProvider(auth)
;(async () => {
  configureAbly({
    useTokenAuth: true,
    authCallback: async (data, callback) => {
      const user = await api.user.currentUser()
      if (user) {
        const token = await subscription.getJWT()
        callback(null, token)
        return token
      } else {
        callback('Unauthorized', null)
        return null
      }
    },
  })
})()
