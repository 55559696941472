import { Badge, Lease } from 'api/hello'
import { createContext } from 'react'

export type LeaseSummary = {
  lease_id: string
  lease: Lease
  title: string
  paymentBadge?: Badge
  checklistBadge?: Badge
  own: boolean
}

export type LeaseSummaryState = LeaseSummary[]

export const LeaseSummaryContext = createContext<
  [state: LeaseSummaryState, reload: () => Promise<void>]
>([[], () => Promise.resolve()])
