import { AutoPaymentSettings, Badge, Lease } from 'api/hello'
import { getMyHomeLease, getMyHomeLeaseAutopay, getMyHomeLeasePayment, routes } from 'const/routes'
import { IconLease, IconSalary, IconMaintenance } from 'icons'
import { FC } from 'react'
import { Dropdown } from 'ui'

interface Props {
  lease: Lease
  paymentBadge?: Badge
  leaseBadge?: Badge
  itemClassName?: string
  own?: boolean
}

export const LeaseMenuItems: FC<Props> = ({
  lease,
  paymentBadge,
  leaseBadge,
  itemClassName,
  own,
}) => {
  if (own) {
    return (
      <>
        <Dropdown.Item
          to={`${getMyHomeLease(lease)}/${routes.myHomeLeaseDetails}`}
          title="Lease"
          icon={<IconLease />}
          {...leaseBadge}
          className={itemClassName}
        />
        {Lease.isMonthlyPaymentEnabled(lease) && (
          <Dropdown.Item
            to={getMyHomeLeasePayment(lease)}
            title="Make Payment"
            icon={<IconSalary />}
            {...paymentBadge}
            className={itemClassName}
          />
        )}
        {Lease.isMonthlyPaymentEnabled(lease) && (
          <Dropdown.Item
            to={getMyHomeLeaseAutopay(lease)}
            title={AutoPaymentSettings.Singular}
            className={itemClassName}
          />
        )}
        <Dropdown.Item
          to={`${getMyHomeLease(lease)}/${routes.myHomeLeaseHistory}`}
          title="Payment History"
          icon={<IconSalary />}
          className={itemClassName}
        />
        {Lease.isMonthlyPaymentEnabled(lease) && (
          <Dropdown.Item
            to={`${getMyHomeLease(lease)}/${routes.myHomeLeaseMaintenance}`}
            title="Maintenance"
            icon={<IconMaintenance />}
            className={itemClassName}
          />
        )}
      </>
    )
  }

  return (
    <Dropdown.Item
      to={`${getMyHomeLease(lease)}/${routes.myHomeLeaseDetails}`}
      title="Lease"
      icon={<IconLease />}
      {...leaseBadge}
      className={itemClassName}
    />
  )
}
