import { Badge, Severity } from 'api/hello'

export const getTotalBadge = (badges: undefined | (Badge | undefined)[]): Badge => {
  return (
    badges?.reduce(
      (result, badge) =>
        badge
          ? {
              amount: (result?.amount ?? 0) + (badge?.amount ?? 0),
              severnity: [result?.severnity, badge?.severnity].includes(Severity.error)
                ? Severity.error
                : Severity.warning,
            }
          : result,
      { amount: 0, severnity: Severity.warning } as Badge,
    ) ?? {
      amount: 0,
      severnity: Severity.warning,
    }
  )
}
