import { useUser } from 'components/use-user'
import { routes } from 'const/routes'
import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

interface Props {
  out?: string | null | false
  hideWhileLoading?: boolean
  children?: ReactNode
  replace?: boolean
}

export const SignedIn: FC<Props> = ({
  out = routes.signUp,
  replace,
  children,
  hideWhileLoading,
}) => {
  const [user, loading] = useUser()
  if (!loading && !user) {
    return out ? <Navigate to={out} replace={replace} /> : null
  }
  return loading && hideWhileLoading ? null : <>{children}</>
}
