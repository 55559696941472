import { LeaseMenu } from 'components/lease-menu'
import { useUserMenu } from 'components/use-user-menu'
import { UserName } from 'components/user-name'
import { routes } from 'const/routes'
import { Variants } from 'framer-motion'
import { AccountIcon, IconClose, IconHelp, IconSettings } from 'icons'
import { FC } from 'react'
import { Avatar, Dropdown, useDropdownContext, Portal } from 'ui'
import { cn } from 'utils'
import { useFirebaseUser } from 'web-auth'
import styles from './content.module.scss'

interface Props {
  className?: string
}

const variants: Variants = {
  hidden: {
    translateX: '-100vw',
    transition: {
      delay: 0.3,
    },
  },
  show: {
    translateX: 0,
  },
}

export const MobileMenuContent: FC<Props> = ({ className }) => {
  const [summaries] = useUserMenu()
  const firebaseUser = useFirebaseUser()
  const { opened } = useDropdownContext() ?? {}
  return (
    <Portal scrollLock={opened}>
      <Dropdown.Menu
        className={cn(styles.nav, className)}
        variants={variants}
        initial="hidden"
        animate="show"
        exit="hidden"
      >
        <div className={styles.userpick}>
          <Avatar src={firebaseUser?.photoURL} />
          <UserName className={styles.name} />
        </div>

        <h2>My Home</h2>

        <LeaseMenu summaries={summaries} unitClassName={styles.lease} itemClassName={styles.item} />

        <h2>Account</h2>
        <Dropdown.Item to={routes.profile} title="Renters Profile" icon={<AccountIcon />} />
        <Dropdown.Item to={routes.account} title="Settings" icon={<IconSettings />} />
        <Dropdown.Item to={routes.help} title="Help" icon={<IconHelp />} />

        <Dropdown.Toggle className={styles.close}>
          <IconClose />
        </Dropdown.Toggle>
      </Dropdown.Menu>
    </Portal>
  )
}
