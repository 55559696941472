import { Lease, Unit } from 'api/hello'
import { getTotalBadge } from 'components/use-user-menu/badge'
import { LeaseSummary } from 'components/use-user-menu/context'
import { getMyHomeLease } from 'const/routes'
import { IconHome } from 'icons'
import { FC, Fragment } from 'react'
import { Dropdown, PeriodUTC } from 'ui'
import { cn } from 'utils'
import { LeaseMenuItems } from './lease-menu-items'
import styles from './lease-menu.module.scss'

interface Props {
  summaries: LeaseSummary[]
  unitClassName?: string
  itemClassName?: string
}

export const LeaseMenu: FC<Props> = ({ summaries, unitClassName, itemClassName }) => {
  const leasesByUnit: Record<string, LeaseSummary[]> = {}
  summaries.forEach((item) => {
    const unit_id = item.lease.unit?.unit_id ?? item.lease.unit_id
    if (!unit_id) return
    if (!leasesByUnit[unit_id]) {
      leasesByUnit[unit_id] = [] as LeaseSummary[]
    }
    leasesByUnit[unit_id].push(item)
  })

  return (
    <>
      {Object.entries(leasesByUnit).map(([unit_id, summaries], index) => {
        const showDates = summaries.length > 1

        const { own, lease } = summaries[0]
        const title = own ? lease.unit?.name || Unit.Singular : 'Guarantee Lease'

        const badge = getTotalBadge(
          summaries.flatMap((summary) => [summary.checklistBadge, summary.paymentBadge]),
        )

        return (
          <Dropdown.Group
            key={unit_id}
            title={title}
            path={getMyHomeLease(lease)}
            icon={<IconHome />}
            className={unitClassName}
            defaultOpened={index === 0}
            {...badge}
          >
            {summaries.map((summary) => (
              <Fragment key={summary.lease.lease_id}>
                {showDates && (
                  <PeriodUTC
                    start={summary.lease.start_at}
                    end={summary.lease.end_at}
                    className={cn(styles.period, Lease.isCurrent(summary.lease) && styles.current)}
                  />
                )}
                <LeaseMenuItems
                  lease={summary.lease}
                  itemClassName={itemClassName}
                  paymentBadge={summary.paymentBadge}
                  leaseBadge={summary.checklistBadge}
                  own={summary.own}
                />
              </Fragment>
            ))}
          </Dropdown.Group>
        )
      })}
    </>
  )
}
