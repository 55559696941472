import { Chat } from 'api/chat'
import { User } from 'api/user'
import { ACCEPT_DOC, ACCEPT_IMG } from 'api/user-file'
import { IconChat, IconClose } from 'icons'
import { Suspense, FC, ComponentProps, useState, useCallback, lazy } from 'react'
import { IconButton } from 'ui'
import { cn } from 'utils'
import styles from './floating-button.module.scss'
import { ChatFilePreview } from '../file-preview'
import { UserNotificationBadge } from '../src/user-notification-badge'
import './chat-window-transition.module.css'

interface Props extends ComponentProps<typeof IconButton> {
  currentUser?: User.Brief | null
  windowClassName?: string
}

export const FloatingButton: FC<Props> = ({
  children,
  className,
  currentUser,
  windowClassName,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [attachment, setAttachment] = useState<Chat.MessageActionOpenAttachment>()

  const toggle = useCallback(() => {
    if (document.startViewTransition) {
      document.startViewTransition(() => {
        setOpen((prev) => !prev)
      })
    } else {
      setOpen((prev) => !prev)
    }
  }, [])

  const viewAttachment = useCallback((attachment: Chat.MessageActionOpenAttachment) => {
    if (ACCEPT_IMG.includes(attachment.userFile.mimetype)) {
      setAttachment(attachment)
    } else if (attachment.blob && ACCEPT_DOC.includes(attachment.userFile.mimetype)) {
      window.open(URL.createObjectURL(attachment.blob), '_blank')
    }
  }, [])

  if (!currentUser) return null

  return (
    <>
      <IconButton {...props} className={cn(styles.button, className)} onClick={toggle}>
        {open ? <IconClose /> : <IconChat />}
        {currentUser && !open && (
          <UserNotificationBadge user={currentUser} className={styles.amount} />
        )}
      </IconButton>
      {currentUser && open ? (
        <div className={styles.window}>
          <Suspense fallback={null}>
            <LazyChatWindow
              onClose={toggle}
              currentUser={currentUser}
              onAttachmentClick={viewAttachment}
            />
          </Suspense>
        </div>
      ) : (
        <div className={cn(styles.window, styles.closed)} />
      )}

      {attachment?.blob && (
        <ChatFilePreview
          url={URL.createObjectURL(attachment.blob)}
          title={attachment.userFile.filename}
          type={attachment.userFile.mimetype}
          className={styles.preview}
          onClose={() => setAttachment(undefined)}
        />
      )}
    </>
  )
}

const LazyChatWindow = lazy(() => import('../chat-window'))
