import { chat as chatApi } from 'api/chat'
import { User } from 'api/user'
import { useUserChatSubscription } from 'api-hooks'
import { FC, ComponentProps, useState, useEffect } from 'react'
import { cn } from 'utils'
import styles from './user-notification-badge.module.scss'

interface Props extends Omit<ComponentProps<'span'>, 'children'> {
  user: User.Brief
}
export const UserNotificationBadge: FC<Props> = ({ className, user, ...props }) => {
  const [hasUpdate, setHasUpdate] = useState(false)

  useEffect(() => {
    chatApi.count({ filter: { user_id: [user.user_id], unread_count: '>0' } }).then((count) => {
      if (count > 0) setHasUpdate(true)
    })
  }, [user.user_id])

  useUserChatSubscription(user.user_id, () => {
    setHasUpdate(true)
  })
  return <span {...props} className={cn(styles.dot, hasUpdate && styles.update)} />
}
