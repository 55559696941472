import { VideoBackground } from 'components/video-background'
import poster1k from 'img/poster-1024.jpg'
import poster2k from 'img/poster-2048.jpg'
import poster4k from 'img/poster.jpg'
import { ComponentProps, FC } from 'react'

const VIDEO = ['/home/video.mp4', '/home/video.webm']
const POSTER_SRC = `${poster1k} 1024w, 
  ${poster2k} 2048w,
  ${poster4k} 4096w`
const POSTER_SIZES = `(max-width: 1024px) 1024w,
(max-width: 2048px) 2048w,
4096w`

interface Props
  extends Omit<
    ComponentProps<typeof VideoBackground>,
    'src' | 'poster' | 'posterSrcSet' | 'posterSizes'
  > {}
export const RelloBackground: FC<Props> = ({ children, ...props }) => {
  return (
    <VideoBackground
      {...props}
      src={VIDEO}
      poster={poster4k}
      posterSrcSet={POSTER_SRC}
      posterSizes={POSTER_SIZES}
    >
      {children}
    </VideoBackground>
  )
}
