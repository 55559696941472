import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'

export type LayoutConfig = {
  header?: 'sticky' | 'static' | 'none'
  noChat?: boolean
}

export type SetLayoutConfig = Dispatch<SetStateAction<LayoutConfig>>

export const defaultLayoutConfig: LayoutConfig = { header: 'static', noChat: false }

export const LayoutContext = createContext<[LayoutConfig, SetLayoutConfig] | null>(null)

export const useLayoutConfig = ({ header, noChat }: LayoutConfig) => {
  const [_prevConfig, setConfig] = useContext(LayoutContext) ?? []
  const [{ header: prevHeader, noChat: prevNoChat }] = useState<LayoutConfig>(
    _prevConfig ?? defaultLayoutConfig,
  )
  useEffect(() => {
    if (header === prevHeader && noChat === prevNoChat) return
    setConfig?.({ header, noChat })
    return () => {
      setConfig?.({ header: prevHeader, noChat: prevNoChat })
    }
  }, [header, noChat, prevHeader, prevNoChat, setConfig])
}
