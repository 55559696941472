import { FC, ReactEventHandler, ReactNode, useState } from 'react'
import { cn } from 'utils'
import styles from './video-background.module.scss'

interface Props {
  className?: string
  shadeClassName?: string
  contentClassName?: string
  play?: boolean
  poster: string
  posterSrcSet?: string
  posterSizes?: string
  src: string[]
  children?: ReactNode
}
export const VideoBackground: FC<Props> = ({
  className,
  play,
  poster,
  src,
  children,
  posterSrcSet,
  posterSizes,
  shadeClassName,
  contentClassName,
  ...props
}) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const handleLoad: ReactEventHandler<HTMLVideoElement> = () => {
    setLoaded(true)
  }

  return (
    <div {...props} className={cn(styles.container, className)}>
      <img
        className={styles.poster}
        alt=""
        srcSet={posterSrcSet}
        sizes={posterSizes}
        src={poster}
      />

      {play && (
        <video
          disablePictureInPicture
          preload="auto"
          playsInline
          autoPlay
          muted
          poster={poster}
          className={cn(styles.video, loaded && styles.loaded)}
          onCanPlayThrough={handleLoad}
        >
          {src.map((url) => (
            <source src={url} type={`video/${url.replace(/.*\.(\w+)$/, '$1')}`} key={url} />
          ))}
        </video>
      )}

      <div className={cn(styles.shade, shadeClassName)} />
      <div className={cn(styles.content, contentClassName)}>{children}</div>
    </div>
  )
}
