import { Client, GetConfig, PostConfig } from 'client'
import { Plaid } from './plaid'

interface GetTokenResponse {
  link_token: string
}

interface ExchangeTokenRequest {
  public_token: string
  metadata?: any
}

interface ExchangeTokenResponse {
  job_id: string
  status: 'success'
}

export class HelloPlaidBackend extends Client {
  getAssetsToken = async (config?: GetConfig): Promise<GetTokenResponse> => {
    return await this.get<any, GetTokenResponse>('/plaid/create/link/assets', undefined, config)
  }

  exchangeToken = async (data: ExchangeTokenRequest, config?: PostConfig) => {
    const result = await this.post<ExchangeTokenRequest, ExchangeTokenResponse>(
      '/plaid/public_token/exchange',
      data,
      config,
    )
    return result
  }

  deleteInstitutionAccounts = async (id: string, config?: PostConfig) => {
    return await this.delete(`/plaid/delete/bank?ins=${id}`, config)
  }

  getJobProgress = async (id: string, config?: GetConfig): Promise<Plaid.JobProgress> => {
    const { progress } = await this.get<{ status: string; progress: Plaid.JobProgress }, any>(
      `/progress/bank`,
      { job: id },
      config,
    )
    return progress
  }
}

export const plaid = new HelloPlaidBackend()
