import { api, CurrentUser } from 'api/hello'
import { createContext, useContext, FC, useEffect, ReactNode, useState } from 'react'

const Context = createContext<[CurrentUser | null | undefined, boolean]>([null, true])
const Provider = Context.Provider

interface Props {
  children?: ReactNode
}
export const UserProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<CurrentUser | null | undefined>()

  useEffect(() => {
    return api.user.addUserListener(setUser)
  }, [setUser])

  return <Provider value={[user, user === undefined]}>{children}</Provider>
}

export const useUser = () => {
  return useContext(Context)
}
