import { useUser } from 'components/use-user'
import { UserName } from 'components/user-name'
import { routes } from 'const/routes'
import { AccountIcon, IconHelp, IconSettings } from 'icons'
import { FC } from 'react'
import { Avatar, Dropdown } from 'ui'
import { cn } from 'utils'
import { useFirebaseUser } from 'web-auth'
import styles from './user-menu.module.scss'

interface Props {
  className?: string
}

export const UserMenu: FC<Props> = ({ className }) => {
  const user = useUser()
  const firebaseUser = useFirebaseUser()
  if (!user) return null

  return (
    <div className={cn(styles.wrapp, className)}>
      <Dropdown.Container>
        <Dropdown.Toggle theme="link" className={styles.toggle}>
          <UserName className={styles.name} />
          <Avatar src={firebaseUser?.photoURL} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item to={routes.profile} title="Renter Profile" icon={<AccountIcon />} />
          <Dropdown.Item to={routes.account} title="Settings" icon={<IconSettings />} />
          <Dropdown.Item to={routes.help} title="Help" icon={<IconHelp />} />
        </Dropdown.Menu>
      </Dropdown.Container>
    </div>
  )
}
