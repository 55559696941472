import { ErrorDialog, ErrorPage } from 'components/error'
import { Layout } from 'components/layout'
import { routes } from 'const/routes'
import NotFoundPage from 'pages/error/404'
import { Root } from 'pages/root'
import Home from 'pages/welcome'
import { Route, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom'

const SIGNATURE_ROUTES = (
  <>
    <Route index element={<Navigate to={routes.accountSignatureDraw} replace />} />
    <Route
      path={routes.accountSignatureDraw}
      lazy={() => import('pages/account/signature-edit/draw')}
    />
    <Route
      path={routes.accountSignatureType}
      lazy={() => import('pages/account/signature-edit/type')}
    />
    <Route
      path={routes.accountSignatureLoad}
      lazy={() => import('pages/account/signature-edit/load')}
    />
  </>
)

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route element={<Layout noHeader />} errorElement={<ErrorPage />}>
        <Route path={routes.welcome} element={<Home />}>
          <Route path={routes.signUp} lazy={() => import('pages/welcome/sign-up-dialog')} />
          <Route
            path={routes.invalidEmail}
            lazy={() => import('pages/welcome/invalid-email-dialog')}
          />
          <Route
            path={routes.passwordSignUp}
            lazy={() => import('pages/welcome/password-sign-up-dialog')}
          />
          <Route
            path={routes.passwordLogin}
            lazy={() => import('pages/welcome/password-login-dialog')}
          />
          <Route
            path={routes.passwordRecovery}
            lazy={() => import('pages/welcome/password-recovery-dialog')}
          />
          <Route
            path={routes.passwordReset}
            lazy={() => import('pages/welcome/password-reset-dialog')}
          />
          <Route
            path={routes.phoneVerifyDialog}
            lazy={() => import('pages/welcome/phone-verify-dialog')}
          />
          <Route
            path={routes.phoneConfirmationDialog}
            lazy={() => import('pages/welcome/phone-confirmation-dialog')}
          />
          <Route path={routes.terms} lazy={() => import('pages/welcome/terms-dialog')} />
          <Route path={routes.infoDialog} lazy={() => import('pages/welcome/info-dialog')} />
        </Route>
        <Route path={routes.streetEasy} lazy={() => import('pages/streeteasy')} />
      </Route>

      <Route path={routes.forPropertyManagers} lazy={() => import('pages/for-property-managers')}>
        <Route
          path={routes.forPropertyManagersContactUs}
          lazy={() => import('pages/for-property-managers/contact-us')}
        />
      </Route>

      <Route element={<Layout />} errorElement={<ErrorPage />}>
        <Route path={routes.paymentCallback} lazy={() => import('pages/payment-callback')} />
        <Route path={routes.account} lazy={() => import('pages/account')}>
          <Route path={routes.accountSignature} lazy={() => import('pages/account/signature-edit')}>
            {SIGNATURE_ROUTES}
          </Route>
          <Route
            path={routes.accountSignatureReset}
            lazy={() => import('pages/account/signature-reset')}
          />
          <Route
            path={routes.deleteAccount}
            lazy={() => import('pages/account/delete-account-dialog')}
          />
          <Route
            path={routes.reloginAccount}
            lazy={() => import('pages/account/relogin-account-dialog')}
          />
          <Route
            path={routes.accountUploadPhotoId}
            lazy={() => import('pages/account/upload-id-dialog')}
          />
          <Route
            path={routes.accountViewPhotoId}
            lazy={() => import('pages/account/view-id-dialog')}
          />
        </Route>
        <Route path={routes.profile} lazy={() => import('pages/profile')}>
          <Route index lazy={() => import('pages/profile/index-step')} />
          <Route path={routes.profileContacts} lazy={() => import('pages/profile/contact')} />
          <Route path={routes.finance} lazy={() => import('pages/profile/finance')}>
            <Route
              path={routes.financeAddBankAccountDialog}
              lazy={() => import('pages/profile/finance/add-bank-account-dialog')}
            />
            <Route
              path={routes.financeAddDocumentDialog}
              lazy={() => import('pages/profile/finance/add-document-dialog')}
            />
            <Route
              path={routes.financeCheckRequiredDocumentsDialog}
              lazy={() => import('pages/profile/finance/required-documents')}
            />
            <Route
              path={routes.financeDeleteDocumentDialog}
              lazy={() => import('pages/profile/finance/delete-document-dialog')}
            />
            <Route
              path={routes.financeUploadDocumentsDialog}
              lazy={() => import('pages/profile/finance/upload-documents-dialog')}
            />
            <Route
              path={routes.financeDeleteInstitutionDialog}
              lazy={() => import('pages/profile/finance/delete-institution-dialog')}
            />
            <Route
              path={routes.financeOAuthAssets}
              lazy={() => import('pages/profile/finance/oauth/assets')}
            />
            <Route
              path={routes.financeScoreWarning}
              lazy={() => import('pages/profile/finance/score-warning')}
            />
            <Route
              path={routes.financeExplainDisabled}
              lazy={() => import('pages/profile/finance/explain-disabled')}
            />
          </Route>
          <Route path={routes.linkedAccounts} lazy={() => import('pages/profile/linked-accounts')}>
            <Route
              path={routes.linkedAccountsDeclineGuaranteeDialog}
              lazy={() => import('pages/profile/linked-accounts/decline-guarantee-dialog')}
            />
            <Route
              path={routes.linkedAccountsAcceptGuaranteeDialog}
              lazy={() => import('pages/profile/linked-accounts/accept-guarantee-dialog')}
            />
            <Route
              path={routes.linkedAccountsAddCosignersDialog}
              lazy={() => import('pages/profile/linked-accounts/add-cosigner-dialog')}
            />
            <Route
              path={routes.linkedAccountsDeclineCosignerDialog}
              lazy={() => import('pages/profile/linked-accounts/decline-cosigner-dialog')}
            />
            <Route
              path={routes.linkedAccountsDeleteCosignerDialog}
              lazy={() => import('pages/profile/linked-accounts/delete-cosigner-dialog')}
            />
            <Route
              path={routes.linkedAccountsAcceptCosignerDialog}
              lazy={() => import('pages/profile/linked-accounts/accept-cosigner-dialog')}
            />
            <Route
              path={routes.linkedAccountsAddGuarantorDialog}
              lazy={() => import('pages/profile/linked-accounts/add-guarantor-dialog')}
            />
            <Route
              path={routes.linkedAccountsDeleteGuarantorDialog}
              lazy={() => import('pages/profile/linked-accounts/delete-guarantor-dialog')}
            />
          </Route>
          <Route
            path={routes.profileAdditionalData}
            lazy={() => import('pages/profile/additional')}
          >
            <Route
              path={routes.profileAdditionalDataNoActions}
              lazy={() => import('pages/profile/additional/no-actions-dialog')}
            />
            <Route
              path={routes.creditScorePayment}
              lazy={() => import('pages/profile/additional/credit-score-payment')}
            />
            <Route
              path={routes.profileAdditionalDataApplicationChecklist}
              lazy={() => import('pages/application-checklist-dialog')}
            />
            <Route
              path={routes.creditScoreStart}
              lazy={() => import('pages/profile/additional/credit-score-start')}
            />
            <Route
              path={routes.creditScoreComplete}
              lazy={() => import('pages/profile/additional/credit-score-complete')}
            />
          </Route>
          <Route path={routes.profileSubmit} lazy={() => import('pages/profile/submit')}>
            <Route
              path={routes.profileSubmitViewPhoto}
              lazy={() => import('pages/profile/submit/gallery')}
            />
          </Route>
          <Route path="*" element={<NotFoundPage inner path={routes.profile} />} />
        </Route>

        <Route
          path={routes.myHomeFinixPayment}
          lazy={() => import('pages/my-home/lease/payment/finix-payment')}
        />

        <Route path={routes.myHome} lazy={() => import('pages/my-home')}>
          <Route index lazy={() => import('pages/my-home/leases')} />

          <Route path={routes.myHomeLease} lazy={() => import('pages/my-home/lease')}>
            <Route index element={<Navigate to={routes.myHomeLeaseDetails} />} />
            <Route
              path={routes.myHomeLeaseDetails}
              lazy={() => import('pages/my-home/lease/details')}
            >
              <Route errorElement={<ErrorDialog />}>
                <Route
                  path={routes.myHomeLeaseDetailsUploadId}
                  lazy={() => import('pages/my-home/lease/details/upload-id-dialog')}
                />
                <Route
                  path={routes.myHomeLeaseDetailsViewId}
                  lazy={() => import('pages/my-home/lease/details/view-id-dialog')}
                />
                <Route
                  path={routes.myHomeLeaseDetailsBackgroundCheck}
                  lazy={() => import('pages/my-home/lease/details/background-check')}
                />
                <Route
                  path={routes.myHomeLeaseDetailsSignatureConfirm}
                  lazy={() => import('pages/my-home/lease/details/signature-confim')}
                />
                <Route
                  path={routes.myHomeLeaseDetailsSign}
                  lazy={() => import('pages/my-home/lease/details/sign')}
                />
                <Route
                  path={routes.myHomeLeaseDetailsContract}
                  lazy={() => import('pages/my-home/lease/details/contract')}
                />
              </Route>
            </Route>
            <Route
              path={routes.myHomeLeasePayment}
              lazy={() => import('pages/my-home/lease/payment')}
            />
            <Route
              path={routes.myHomeLeaseAutopay}
              lazy={() => import('pages/my-home/lease/autopay')}
            >
              <Route
                path={routes.myHomeLeaseAutopayConfirm}
                lazy={() => import('pages/my-home/lease/autopay/confirm-dialog')}
              />
              <Route
                path={routes.myHomeLeaseAutopayCancel}
                lazy={() => import('pages/my-home/lease/autopay/cancel-dialog')}
              />
            </Route>
            <Route
              path={routes.myHomeLeaseHistory}
              lazy={() => import('pages/my-home/lease/history')}
            />
            <Route
              path={routes.myHomeLeaseMaintenance}
              lazy={() => import('pages/my-home/lease/maintenance')}
            >
              <Route
                path={routes.myHomeLeaseMaintenanceSuccess}
                lazy={() => import('pages/my-home/lease/maintenance/success')}
              />
            </Route>
          </Route>
          <Route
            path={routes.myHomePaymentCallback}
            lazy={() => import('pages/my-home/payment-success')}
          />
          <Route
            path={routes.myHomePaymentCancel}
            lazy={() => import('pages/my-home/payment-cancel')}
          />

          <Route path="*" element={<NotFoundPage inner path={routes.myHome} />} />
        </Route>

        <Route path={routes.search} lazy={() => import('pages/search')}>
          <Route path={routes.searchMap} element={<Layout.Use header="sticky" />}>
            <Route index lazy={() => import('pages/search/map')} />
            <Route lazy={() => import('pages/search/map')}>
              <Route path={routes.searchFilters} lazy={() => import('pages/search/filters')} />
              <Route path={routes.searchNoResults} lazy={() => import('pages/search/no-results')} />
            </Route>
          </Route>
          <Route
            path={`${routes.searchMap}/${routes.unitDetails}`}
            lazy={() => import('pages/search/details')}
          >
            <Route
              path={routes.unitDetailsGallery}
              lazy={() => import('pages/search/details/gallery')}
            />
            <Route path={routes.unitDetailsInfo} lazy={() => import('pages/search/details/info')} />
            <Route
              path={routes.unitDetailsApplicationChecklist}
              lazy={() => import('pages/application-checklist-dialog')}
            />
            <Route path={routes.creditScoreStart} lazy={() => import('pages/credit-score-start')} />
            <Route
              path={routes.creditScoreComplete}
              lazy={() => import('pages/credit-score-complete')}
            />
            <Route
              path={routes.unitDetailsApplication}
              lazy={() => import('pages/search/details/application')}
            />
            <Route path={routes.unitDetailsFee} lazy={() => import('pages/search/details/fee')} />
            <Route path={routes.unitDetailsBid} lazy={() => import('pages/search/details/bid')} />
            <Route
              path={routes.unitDetailsCancelBid}
              lazy={() => import('pages/search/details/cancel')}
            />
            <Route
              path={routes.unitDetailsRentNow}
              lazy={() => import('pages/search/details/rent-now')}
            />
            <Route
              path={routes.unitDetailsWhyCantBid}
              lazy={() => import('pages/search/details/why')}
            />
            <Route
              path={routes.unitDetailsSuccessPayment}
              lazy={() => import('pages/search/details/success')}
            />
          </Route>

          <Route path={routes.searchGrid}>
            <Route index lazy={() => import('pages/search/grid')} />
            <Route lazy={() => import('pages/search/grid')}>
              <Route path={routes.searchFilters} lazy={() => import('pages/search/filters')} />
              <Route path={routes.searchNoResults} lazy={() => import('pages/search/no-results')} />
            </Route>
            <Route path={routes.unitDetails} lazy={() => import('pages/search/details')}>
              <Route
                path={routes.unitDetailsGallery}
                lazy={() => import('pages/search/details/gallery')}
              />
              <Route
                path={routes.unitDetailsInfo}
                lazy={() => import('pages/search/details/info')}
              />
              <Route
                path={routes.unitDetailsApplicationChecklist}
                lazy={() => import('pages/application-checklist-dialog')}
              />
              <Route
                path={routes.creditScoreStart}
                lazy={() => import('pages/credit-score-start')}
              />
              <Route
                path={routes.creditScoreComplete}
                lazy={() => import('pages/credit-score-complete')}
              />
              <Route
                path={routes.unitDetailsApplication}
                lazy={() => import('pages/search/details/application')}
              />
              <Route path={routes.unitDetailsFee} lazy={() => import('pages/search/details/fee')} />
              <Route path={routes.unitDetailsBid} lazy={() => import('pages/search/details/bid')} />
              <Route
                path={routes.unitDetailsCancelBid}
                lazy={() => import('pages/search/details/cancel')}
              />
              <Route
                path={routes.unitDetailsRentNow}
                lazy={() => import('pages/search/details/rent-now')}
              />
              <Route
                path={routes.unitDetailsWhyCantBid}
                lazy={() => import('pages/search/details/why')}
              />
            </Route>
          </Route>
        </Route>

        <Route path={routes.about} lazy={() => import('pages/about')} />
        <Route path={routes.help} lazy={() => import('pages/help')} />
        <Route path={routes.contacts} lazy={() => import('pages/contact-us')} />
        <Route path={routes.privacyPolicy} lazy={() => import('pages/privacy-policy')} />
        <Route path={routes.termsOfService} lazy={() => import('pages/terms-of-service')} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Route>,
  ),
)
